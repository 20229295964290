import React, { useEffect } from "react";
import { useState } from "react";
import { StyledGridRow } from "../../commons/Grid.js";
import useApi from "../../hooks/useApi.js";
import {
  StyledEmailFeedback,
  StyledEmailFeedbackTitle,
  StyledEmailFeedbackSubtitle,
  StyledEmailFeedbackMarks,
  StyledEmailFeedbackMark,
  StyledEmailFeedbackMarkEmoji,
} from "./style.jsx";

const EmailFeedback = ({
  title,
  subtitle,
}) => {
  const { sendEmailMark } = useApi();
  const [ mark, setMark ] = useState();
  const [ counter, setCounter ] = useState(0);
  const submitMark = (mark) => {
    if (counter === 2) {
      return;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailTrackingId = urlParams.get("email_tracking_id");
    const token = urlParams.get("token");

    sendEmailMark(emailTrackingId, token, mark);
    setMark(mark);
    setCounter(counter + 1);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailMark = parseInt(urlParams.get("email_mark"));

    setMark(emailMark);
    submitMark(emailMark);
  }, []);

  return (
    <StyledEmailFeedback>
      <StyledGridRow>
        <StyledEmailFeedbackTitle dangerouslySetInnerHTML={{ __html: title }} />
        <StyledEmailFeedbackSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />

        <StyledEmailFeedbackMarks>
          <StyledEmailFeedbackMark>
            <StyledEmailFeedbackMarkEmoji onClick={() => submitMark(1)}>&#128545;</StyledEmailFeedbackMarkEmoji>
            <StyledEmailFeedbackMarkEmoji isHidden={mark !== 1}>&nbsp;&#128070;</StyledEmailFeedbackMarkEmoji>
          </StyledEmailFeedbackMark>
          <StyledEmailFeedbackMark>
            <StyledEmailFeedbackMarkEmoji onClick={() => submitMark(2)}>&#128530;</StyledEmailFeedbackMarkEmoji>
            <StyledEmailFeedbackMarkEmoji isHidden={mark !== 2}>&nbsp;&#128070;</StyledEmailFeedbackMarkEmoji>
          </StyledEmailFeedbackMark>
          <StyledEmailFeedbackMark>
            <StyledEmailFeedbackMarkEmoji onClick={() => submitMark(3)}>&#128529;</StyledEmailFeedbackMarkEmoji>
            <StyledEmailFeedbackMarkEmoji isHidden={mark !== 3}>&nbsp;&#128070;</StyledEmailFeedbackMarkEmoji>
          </StyledEmailFeedbackMark>
          <StyledEmailFeedbackMark>
            <StyledEmailFeedbackMarkEmoji onClick={() => submitMark(4)}>&#128578;</StyledEmailFeedbackMarkEmoji>
            <StyledEmailFeedbackMarkEmoji isHidden={mark !== 4}>&nbsp;&#128070;</StyledEmailFeedbackMarkEmoji>
          </StyledEmailFeedbackMark>
          <StyledEmailFeedbackMark>
            <StyledEmailFeedbackMarkEmoji onClick={() => submitMark(5)}>&#128525;</StyledEmailFeedbackMarkEmoji>
            <StyledEmailFeedbackMarkEmoji isHidden={mark !== 5}>&nbsp;&#128070;</StyledEmailFeedbackMarkEmoji>
          </StyledEmailFeedbackMark>
        </StyledEmailFeedbackMarks>
      </StyledGridRow>
    </StyledEmailFeedback>
  );
};

export default EmailFeedback;
